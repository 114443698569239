<script setup>
import DataTable from "@/components/common/DataTable.vue"
import PaginationControl from "@/components/common/PaginationControl.vue"
import { usePayoutStore } from "@/stores/Payouts"
import { useSalesStore } from "@/stores/Sales"
import { computed, onMounted, ref } from "vue"

const salesStore = useSalesStore();
const payoutStore = usePayoutStore();
const currentPage = ref(1);

const titlesTable = computed(() => [
  {
    width: "",
    title: "Monto",
  },
  {
    width: "w-4/12",
    title: "Fecha",
  },
]);

onMounted(async () => {
  await salesStore.getSales();
  await payoutStore.getPayouts(currentPage.value);
});

const handlePageChange = async (page) => {
  currentPage.value = page;
  await payoutStore.getPayouts(page);
};

function formatDate(dateString) {
  const date = new Date(dateString);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}
</script>

<template>
  <section>
    <div class="main-title">
      <h2>Pagos</h2>
    </div>
    <DataTable :noHaveData="payoutStore.data?.length === 0" :headers="titlesTable">
      <template #body>
        <tr v-for="(item, index) in payoutStore.data" :key="index">
          <td class="p-3" v-text="item.amount || '-'" />
          <td class="p-3" v-text="formatDate(item.created_at) || '-'" />
        </tr>
      </template>
    </DataTable>

    <PaginationControl
      v-if="payoutStore.data?.length"
      :current-page="currentPage"
      :total-pages="payoutStore.pageCount"
      @page-change="handlePageChange"
    />
  </section>
</template>