<template>
  <div class="login-container">
    <div class="login-box">
      <div class="logo-container">
        <img src="@/assets/logo-skillpers.png" alt="Skillpers Logo" class="logo">
      </div>

      <h1 class="login-title">Iniciar sesión</h1>

      <form @submit.prevent="handleSubmit" class="login-form">
        <div class="input-group">
          <div class="input-wrapper">
            <span class="input-icon">✉</span>
            <input 
              type="email" 
              v-model="email" 
              placeholder="correo@ejemplo.com"
              class="form-input"
              required
            >
          </div>
        </div>

        <div class="input-group">
          <div class="input-wrapper">
            <span class="input-icon">🔒</span>
            <input 
              type="password" 
              v-model="password" 
              placeholder="contraseña"
              class="form-input"
              required
            >
          </div>
        </div>

        <button 
          type="submit" 
          class="login-button"
          :disabled="isLoading"
        >
          {{ isLoading ? 'INICIANDO SESIÓN...' : 'INICIAR SESIÓN' }}
          <span v-if="!isLoading" class="button-arrow">→</span>
        </button>

        <div v-if="error" class="error-message">
          {{ error }}
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuth } from '@/composables/useAuth';

const router = useRouter();
const { handleCustomLogin, isLoading, error } = useAuth();

const email = ref('');
const password = ref('');

const handleSubmit = async () => {
  const success = await handleCustomLogin(email.value, password.value);
  if (success) {
    router.push('/');
  }
};
</script>

<style scoped>
.login-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e2023;
  padding: 20px;
}

.login-box {
  background: white;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 2rem;
}

.logo-container {
  text-align: center;
  margin-bottom: 1.5rem;
}

.logo {
  height: 120px;
  width: auto;
}

.login-title {
  text-align: center;
  font-size: 1rem;
  color: #333;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ff6b00;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-wrapper {
  background-color: #f5f5f5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
}

.input-icon {
  color: #666;
  margin-right: 0.5rem;
  font-size: 0.875rem;
}

.form-input {
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  font-size: 0.875rem;
  color: #333;
}

.form-input::placeholder {
  color: #999;
}

.login-button {
  background-color: #ff6b00;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem;
  font-size: 0.875rem;
  cursor: pointer;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
}

.button-arrow {
  font-size: 1rem;
}

.login-button:hover:not(:disabled) {
  background-color: #e65c00;
}

.login-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.error-message {
  color: #dc2626;
  text-align: center;
  font-size: 0.875rem;
  margin-top: 1rem;
}
</style>