import axios from "axios"
import { defineStore } from "pinia"

export default defineStore({
  id: "producer",
  state: () => ({
    _status: null,
    _error: null,
    _token: sessionStorage.getItem("token") || null,
    _user: JSON.parse(sessionStorage.getItem("profile")) || null,
    _company: JSON.parse(sessionStorage.getItem("company")) || null,
    _profile: null,
    _producer: null,
    _isProd: false,
    _verifiedUser: false
  }),
  getters: {
    isLoading: (state) => state._status === "loading",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    token: (state) => state._token,
    user: (state) => state._user,
    company: (state) => state._company,
    profile: (state) => state._profile,
    producer: (state) => state._producer,
    isProd: (state) => state._isProd,
    verifiedUser: (state) => state._verifiedUser,
  },
  actions: {
    async getUserProfile(auth0Id) {
      this.changeStatus("loading");
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/profiles/${auth0Id}/`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        console.log(response);
        this._profile = response.data;
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching profile:", error);
      }
    },
    async saveProfile(payload) {
      this.changeStatus("loading");
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/producer-profile-update/`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        console.log(response);
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching profile:", error);
      }
    },
    async getProducer(auth0Id) {
      this.changeStatus("loading");
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/producers/${auth0Id}/`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        this._producer = response.data;
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching profile:", error);
      }
    },
    async verifyProducer(auth0Id) {
      this.changeStatus("loading");
      this._verifiedUser = 'loading'
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/producers/${auth0Id}/`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.status == 200){
          this._verifiedUser = 'verified'
          this._producer = response.data;
        }
        this.changeStatus("ready");
      } catch (error) {
        console.log(error);
        this._verifiedUser = 'error'
        this.changeStatus("error");
      }
    },
    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
  },
});
