<template>
  <div class="main-title">
    <h2>{{ $t("PROFILE.TITLE") }}</h2>
    <button
      v-if="!edit"
      @click="edit = !edit"
      class="btn btn-dark"
    >
      <span v-if="!edit"> {{ $t("PROFILE.EDIT") }} </span>
    </button>
    <button
      v-else
      class="btn btn-primary"
      @click="save"
    >
      <span>{{ $t("PROFILE.SAVE") }}</span>
    </button>
  </div>
  <section>
    <div class="bg-sections d-flex flex-column-reverse flex-md-row mb-2">
      <div class="profile">
        <button v-if="!isAuthenticated" @click="login" class="btn btn-primary">
          {{ $t("PROFILE.LOGIN") }}
        </button>
        <div v-else>
          <div class="user-details">
            <img
              :src="userInfo?.picture || defaultAvatar"
              alt="Profile Picture"
              class="profile-picture mb-3"
            />
            <h3>{{ userInfo?.name || userInfo?.email || 'Usuario' }}</h3>
            <p>{{ userInfo?.email }}</p>
          </div>
          <button @click="logOut" class="btn mb-3 btn-secondary">
            {{ $t("PROFILE.LOGOUT") }}
          </button>
        </div>
      </div>
      <div class="mx-4 w-full">
        <p class="text-secondary" v-text="$t('PROFILE.INFO')"></p>
        <span class="">Contactenos si desea cambiar esta informacion</span>
        <div class="d-flex flex-column flex-md-row gap-4 w-50">
          <ProfileForm :form-data="{
            given_name: userInfo?.given_name,
            family_name: userInfo?.family_name,
            email: userInfo?.email,
            phone: producerStore?.profile?.user?.phone
          }" :edit="edit" />
        </div>
      </div>
    </div>

    <div class="w-full">
      <CompanyForm :edit="edit"/>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UserProfile',
}
</script>

<script setup>
import CompanyForm from "@/components/forms/CompanyForm.vue"
import ProfileForm from "@/components/forms/ProfileForm.vue"
import useProducerStore from "@/stores/Producer"
import { onMounted, ref } from "vue"
import { useRouter } from 'vue-router'

const router = useRouter();
const producerStore = useProducerStore();
const edit = ref(false);
const userInfo = ref({});
const isAuthenticated = ref(false);
const defaultAvatar = 'https://cdn.auth0.com/avatars/default.png';

const fetchUserInfo = async () => {
  try {
    const token = sessionStorage.getItem('token');
    if (!token) return null;

    const response = await fetch(`https://${process.env.VUE_APP_AUTH0_DOMAIN}/userinfo`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user info');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
};

const checkAuthentication = () => {
  const token = sessionStorage.getItem('token');
  if (!token) return false;
  
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp * 1000 > Date.now();
  } catch {
    return false;
  }
};

const login = () => {
  router.push('/login');
}

const logOut = () => {
  sessionStorage.removeItem('token');
  userInfo.value = null;
  isAuthenticated.value = false;
  router.push('/login');
}

onMounted(async () => {
  if (!checkAuthentication()) {
    router.push('/login');
    return;
  }

  isAuthenticated.value = true;
  const userData = await fetchUserInfo();
  
  if (!userData) {
    router.push('/login');
    return;
  }

  userInfo.value = userData;
  await producerStore.getUserProfile(userData.sub);
  await producerStore.getProducer(userData.sub);
});

const save = async () => {
  edit.value = false;
  await producerStore.saveProfile({
    company_name: producerStore.producer?.company_name,
    entity_type: producerStore.producer?.entity_type,
    registration_number: producerStore.producer?.registration_number,
    tax_id_number: producerStore.producer?.tax_id_number,
    vat_id_number: producerStore.producer?.vat_id_number,

    city: producerStore.profile.city,
    address: producerStore.profile.address,
    country: producerStore.profile.country,
    state: producerStore.profile.state,
    zip_code: producerStore.profile.zip_code,
    apartment: producerStore.profile.apartment,
    payload_email: producerStore.profile.payload_email,
    account_type: producerStore.profile.account_type,

    request_type: 'producer',
  });
}
</script>

<style scoped>
.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.user-details {
  text-align: center;
}

.main-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.bg-sections {
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile {
  padding: 1.5rem;
  text-align: center;
}

.btn {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 500;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  color: white;
}

.btn-dark {
  background-color: #343a40;
  border-color: #343a40;
  color: white;
}

.w-full {
  width: 100%;
}

.text-secondary {
  color: #6c757d;
}

.gap-4 {
  gap: 1rem;
}

@media (max-width: 768px) {
  .d-flex.flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
</style>