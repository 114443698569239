import axios from "axios"
import { defineStore } from "pinia"
import { useCoreStore } from './core'

export const useSalesStore = defineStore("sales", {
  state: () => ({
    _status: null,
    _error: null,
    _sales: null,
    _total: 0,
    _pageCount: 0,
    _page: 0,
    _statusSales: null,
    _sale: null,
    _totalAmountAffiliate: null,
    _totalAmountProducer: null
  }),
  getters: {
    isLoading: (state) => state._status === "loading",
    isLoadingAll: (state) => state._status === "loadingAll",
    isReadyAll: (state) => state._status === "readyAll",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    sales: (state) => state._sales,
    total: (state) => state._total,
    pageCount: (state) => state._pageCount,
    page: (state) => state._page,
    statusSales: (state) => state._statusSales,
    sale: (state) => state._sale,
    totalAmountAffiliate: (state) => state._totalAmountAffiliate,
    totalAmountProducer: (state) => state._totalAmountProducer
  },
  actions: {
    async getSales(
      product = undefined,
      dateFrom = undefined,
      dateTo = undefined,
      pageNumber = 1,
      state = undefined,
      producerName = undefined,
      name = undefined,
      lastName = undefined,
      email = undefined,
      sortObject
    ) {
      const coreStore = useCoreStore();
      const subdomain = coreStore.subdomain;
      let url = `${process.env.VUE_APP_API_URL}/api/sales/sales_summary/?type=${subdomain === 'skillper' ? 'producer' : 'affiliate'}&page=${pageNumber}`;

      this.changeStatus("loading");

      if (product) {
        url += `&product_name=${product}`;
      }

      if (dateFrom) {
        url += `&date_from=${dateFrom}`;
      }

      if (dateTo) {
        url += `&date_to=${dateTo}`;
      }

      if (state) {
        url += `&status=${state}`;
      }
      if (name) {
        url += `&user_first_name=${name}`;
      }
      if (lastName) {
        url += `&user_last_name=${lastName}`;
      }
      if (email) {
        url += `&user_email=${email}`;
      }

      if (producerName) {
        url += `&producer_name=${producerName}`;
      }
      if (sortObject?.value) {
        url += `&order=${[sortObject?.value]}:${sortObject?.order}`;
      }

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        });
        this._pageCount = Math.ceil(response.data.count/10);
        this._page = pageNumber;
        this._sales = response.data;
        this._total = response.data.total_amount;
        this._totalAmountAffiliate = response.data.total_amount_affiliate
        this._totalAmountProducer = response.data.total_amount_producer
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching list:", error);
      }
    },
    async getStatus() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/sale-status/`
        );
        this._statusSales = response.data.results;
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async getSale(id) {
      const coreStore = useCoreStore();
      const subdomain = coreStore.subdomain;

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/sales/${id}/?type=${subdomain === 'skillper' ? 'producer' : 'affiliate'}`,{
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this._sale = response.data;
      } catch (error) {
        console.error("Error ", error);
      }
    },
    async refund({ sale_id }) {
      this.changeStatus("loading");
      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/api/refund/`,
          { sale_id },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.changeStatus("ready");
        return true;
      } catch (error) {
        this.changeStatus("error", error);
        console.error("Error refunding sale:", error);
        return false;
      }
    },
    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
  },
});