/* eslint-disable */
<script setup>
import DataTable from "@/components/common/DataTable.vue"
import EditableRow from "@/components/common/EditableRow.vue"
import PaginationControl from "@/components/common/PaginationControl.vue"
import axios from "axios"
import { BFormSelect } from "bootstrap-vue-next"
import { computed, onMounted, ref } from "vue"
import { useRoute } from "vue-router"
import { useProductStore } from "@/stores/productStore"
import router from "@/router"
import { toast } from 'vue3-toastify'

const route = useRoute();
const productStore = useProductStore();
const product = ref();
const currentPage = ref(1);

const getUserIdFromToken = () => {
  try {
    const token = sessionStorage.getItem('token');
    if (token) {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.sub;
    }
    return null;
  } catch (error) {
    console.error('Error getting user ID:', error);
    return null;
  }
};

onMounted(async () => {
  const token = sessionStorage.getItem('token');
  if (!token) {
    router.push('/login');
    return;
  }
  
  const userId = getUserIdFromToken();
  if (!userId) {
    router.push('/login');
    return;
  }

  await productStore.getAllProducts(userId);

  if (route.params.id) {
    getAffiliates(route.params.id);
    product.value = route.params.id;
  }
});

const getProductAffiliates = async (_id) => {
  router.push({ name: "affiliates", params: { id: _id} });
  currentPage.value = 1; // Reset to first page when changing products
  await getAffiliates(_id);
};

const getAffiliates = async (productId, pageNumber = 1) => {
  try {
    loading.value = true;
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/api/affiliate-products/?product=${productId}&page=${pageNumber}&request_type=producer`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    currentPage.value = pageNumber;
    count.value = response.data.count;
    data.value = response.data.results;
  } catch (error) {
    console.log(error);
    toast.error('Error al cargar los afiliados');
  } finally {
    loading.value = false;
  }
};

const handlePageChange = async (page) => {
  await getAffiliates(route.params.id, page);
};

const updateAffiliateProduct = async (affiliateProduct, payload) => {
  try {
    const response = await axios.patch(
      `${process.env.VUE_APP_API_URL}/api/affiliate-products/${affiliateProduct.id}/?request_type=producer`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    
    if (response.status === 200) {
      toast.success("Actualizado correctamente");
      return true;
    }
    return false;
  } catch (error) {
    console.error(error);
    const errorMessage = error.response?.data?.detail || 'Error al actualizar';
    toast.error(errorMessage);
    return false;
  }
};

const data = ref([]);
const loading = ref(false);
const titlesTable = computed(() => [
  {
    width: "w-4/12",
    title: "Nombre y apellido",
  },
  {
    width: "w-1/6",
    title: "Email",
  },
  {
    width: "w-1/2",
    title: "Teléfono",
  },
  {
    width: "w-4/12",
    title: "Comisión de producto",
  },
  {
    width: "w-4/12",
    title: "Estatus",
  },
]);

const count = ref(0);
const totalPages = computed(() => Math.ceil(count.value / 10));

const status_choices = [
  { value: "pendiente", text: "Pendiente" },
  { value: "eliminado", text: "Eliminado" },
  { value: "confirmado", text: "Confirmado" },
];

const updateItem = async (updatedItem, originalValue) => {
  try {
    const success = await updateAffiliateProduct(
      updatedItem, 
      { percentage: updatedItem.percentage }, 
      originalValue
    );
    
    if (success) {
      const index = data.value.findIndex(item => item.id === updatedItem.id);
      if (index !== -1) {
        data.value[index] = { ...updatedItem };
      }
      return true;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const handleStatusChange = async (item, newStatus) => {
  const currentStatus = item.status;
  item.status = newStatus;
  
  try {
    const response = await axios.patch(
      `${process.env.VUE_APP_API_URL}/api/affiliate-products/${item.id}/?request_type=producer`,
      { status: newStatus },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    
    if (response.status === 200) {
      toast.success("Estado actualizado correctamente");
    } else {
      item.status = currentStatus;
      toast.error('Error al actualizar el estado');
    }
  } catch (error) {
    item.status = currentStatus;
    console.log(error);
    const errorMessage = error.response?.data?.detail || 'Error al actualizar el estado';
    toast.error(errorMessage);
  }
};
</script>

<template>
  <section>
    <div class="main-title">
      <h2>{{ $t("PRODUCT.AFFILIATES") }}</h2>
    </div>
    <div class="">
      <p v-text="'Productos'" class="mb-0" />
      <select
        class="form-select"
        aria-label="Default select example"
        v-model="product"
        @change="getProductAffiliates(product)"
      >
        <option value="" selected>Seleccionar</option>
        <option
          v-for="(obj, index) in productStore.listProducts.products"
          :key="index"
          :value="obj?.id"
        >
          {{ obj?.title }}
        </option>
      </select>
    </div>
    
    <div class="table-responsive">
      <DataTable
        :noHaveData="data?.length === 0"
        :headers="titlesTable"
        :isLoading="loading"
      >
        <template #body>
          <tr v-for="(item, index) in data" :key="index">
            <td
              class="p-3"
              v-text="
                item.affiliate?.user?.first_name +
                ' ' +
                item.affiliate?.user?.last_name
              "
            />
            <td class="p-3" v-text="item.affiliate.user.email || '-'" />
            <td class="p-3" v-text="item.affiliate.user.phone || '-'" />
            <td class="p-3">
              <EditableRow :item="item" @update-item="updateItem" />
            </td>
            <td class="p-3">
              <BFormSelect
                :model-value="item.status"
                :options="status_choices"
                @update:model-value="handleStatusChange(item, $event)"
              />
            </td>
          </tr>
        </template>
      </DataTable>
    </div>

    <PaginationControl
      v-if="data.length > 0"
      :current-page="currentPage"
      :total-pages="totalPages"
      @page-change="handlePageChange"
    />
  </section>
</template>

<style scoped>
.table-responsive {
  overflow-x: auto;
}
</style>