<template>
    <nav class="flex justify-center">
      <ul class="index-nav flex items-left">
        <li>
          <button
            :class="{
              'cursor-not-allowed opacity-25': currentPage === 1,
            }"
            :disabled="currentPage === 1"
            @click.prevent="handlePageChange(currentPage - 1)"
          >
            Anterior
          </button>
        </li>
  
        <li v-if="totalPages>7 && currentPage >= 5">
          <button
            @click.prevent="handlePageChange(1)"
          >
            1...
          </button>
        </li>
  
        <li v-for="page in paginationPages" :key="page">
          <button
            :disabled="currentPage === page"
            :class="{
              'font-bold': currentPage === page,
              'bg-green text-white': currentPage === page
            }"
            @click.prevent="handlePageChange(page)"
          >
            {{ page }}
          </button>
        </li>
  
        <li v-if="totalPages>7 && currentPage <= totalPages-4">
          <button
            @click.prevent="handlePageChange(totalPages)"
          >
            ...{{ totalPages }}
          </button>
        </li>
  
        <li>
          <button
            :class="{
              'cursor-not-allowed opacity-50': currentPage === totalPages,
            }"
            :disabled="currentPage === totalPages"
            @click.prevent="handlePageChange(currentPage + 1)"
          >
            Siguiente
          </button>
        </li>
      </ul>
    </nav>
  </template>
  
  <script>
  import { computed } from 'vue';
  
  export default {
    name: 'PaginationControl',
    props: {
      currentPage: {
        type: Number,
        required: true
      },
      totalPages: {
        type: Number,
        required: true
      },
      maxDisplayPages: {
        type: Number,
        default: 7
      }
    },
    emits: ['page-change'],
    setup(props, { emit }) {
      const paginationPages = computed(() => {
        const { currentPage, totalPages, maxDisplayPages } = props;
  
        if (totalPages <= maxDisplayPages) {
          return Array.from({ length: totalPages }, (_, i) => i + 1);
        }
  
        let start = Math.max(1, currentPage - Math.floor(maxDisplayPages / 2));
        let end = start + maxDisplayPages - 1;
  
        if (end > totalPages) {
          end = totalPages;
          start = Math.max(1, end - maxDisplayPages + 1);
        }
  
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
      });
  
      const handlePageChange = (page) => {
        if (page < 1 || page > props.totalPages) return;
        emit('page-change', page);
      };
  
      return {
        paginationPages,
        handlePageChange
      };
    }
  }
  </script>
  
  <style scoped>
  .index-nav {
    display: flex;
    gap: 1rem;
    list-style: none;
    padding: 0;
    margin: 1rem 0;
  }
  
  .index-nav button {
    padding: 0.5rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
    background: none;
    cursor: pointer;
  }
  
  .index-nav button:disabled {
    background: #000000;
    color: white;
    cursor: not-allowed;
  }
  
  .bg-green {
    background-color: #10b981;
  }
  </style>