<template>
  <AppHeader />
  <div class="">
    <div class="row">
      <AppSidebar class="col-md-2 col-lg-2" />
      <main class="col-12 layout-main">
        <div class="content-dash">
          <router-view></router-view>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import AppHeader from "@/components/HeaderComponent.vue"
import AppSidebar from "@/components/SideBar.vue"
import { useAffiliateStore } from "@/stores/Affiliate"
import { useCoreStore } from "@/stores/core"
import useProducerStore from "@/stores/Producer"
import { onMounted } from "vue"

const coreStore = useCoreStore();
const subdomain = coreStore.subdomain;
const producerStore = useProducerStore();
const affiliateStore = useAffiliateStore();

onMounted(async () => {
  const token = sessionStorage.getItem('token');
  
  if (token) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      if (payload.sub) {
        await performAuthenticationActions(payload);
      }
    } catch (error) {
      console.error('Error parsing token:', error);
    }
  }
});

async function performAuthenticationActions(userData) {
  const userId = userData?.sub;

  if (subdomain === "skillper") {
    await producerStore.verifyProducer(userId);
  }
  if (subdomain === "affiliate") {
    await affiliateStore.verifyAffiliate(userId);
  }
}
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  z-index: 99999;
  background: #000000a3;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-producer {
  background: #fff;
  border-radius: 10px;
  padding: 50px 10px;
  text-align: center;
  width: 30%;
}
.modal-producer .title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 1rem;
}
.modal-producer svg {
  width: 50px;
  height: 50px;
  color: orange;
}
</style>