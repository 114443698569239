<script setup>
import { ref, watch, computed, onMounted } from "vue"
import DataTable from "@/components/common/DataTable.vue"
import { showNotification } from "@/composables/useNotification"
import { useAffiliateStore } from "@/stores/Affiliate";
import { useOffersStore } from "@/stores/Offers"

const offersStore = useOffersStore();
const affiliateStore = useAffiliateStore()

const utm = ref('');

const setUtm = () => {
  utm.value = affiliateStore._affiliateProducts[0]?.affiliate?.utm || '';
  console.log(utm.value);
};

const titlesTable = computed(() => [
  {
    width: "w-4/12",
    title: "Producto",
  },
  {
    width: "w-4/12",
    title: "Precio",
  },
  {
    width: "w-4/12",
    title: "Link de Oferta",
  },


]);

onMounted(async () => {
  try {
    await Promise.all([
      offersStore.getAffiliateOffers(),
      affiliateStore.getAffiliateProducts()
    ]);
    setUtm();
  } catch (error) {
    console.error('Error loading data:', error);
    showNotification("Error loading data. Please try again.", "error");
  }
});

watch(() => affiliateStore._affiliateProducts, setUtm, { deep: true });

const copyLink = (productId) => {
  const link = `${process.env.VUE_APP_MARKET_URL}/products/detail/${productId}?utm_affiliate=${utm.value}`
  navigator.clipboard.writeText(link).then(() => {
    showNotification("Link copiado", "success");
  }).catch(err => {
    console.error('Failed to copy link: ', err);
    showNotification("Failed to copy link", "error");
  });
}

const goToLink = (productId) => {
  const link = `${process.env.VUE_APP_BILLER_URL}/products/detail/${productId}?utm_affiliate=${utm.value}`
  console.log(link);
  window.open(link, '_blank');
}
</script>

<template>
  <section>
    <section class="mb-4">
      <div class="main-title">
        <h2 class="fw-semibold">{{ $t("MENU.OFFERS") }}</h2>
      </div>
    </section>
    <DataTable :noHaveData="!offersStore?.data?.length" :headers="titlesTable">
      <template #body>
        <tr v-for="(item, index) in offersStore?.data" :key="index">
          <td class="p-3" v-text="item.product.title || '-'" />
          <td class="p-3" v-text="item.product.price || '-'" />
          <td class="p-3">
            <button class="btn bg-green text-white mr-4" @click="goToLink(item.product.id)">
              Ir a oferta
            </button>
            <button class="btn bg-copy text-white" @click="copyLink(item.product.id)">
              <i class="fas fa-copy"></i>
            </button>
          </td>
        </tr>
      </template>
    </DataTable>
  </section>
</template>

<style scoped>
.mr-4{
  margin-right: 10px;
}
.bg-copy{
  background-color: transparent;
  border: 1px solid #ccc;
}
.bg-copy:hover , .bg-copy:hover i{
  background-color: #22C55E;
  color: white;
}
.bg-copy i{
  color: #22C55E;
}
</style>