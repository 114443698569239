<template>
  <div class="content">
    <h2 class="text-center mb-4">Editar producto</h2>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <TextField
          id="productTitle"
          v-model="store.currentProduct.title"
          :errorMessage="
            handlerValidate?.['title']?.$errors?.length > 0
              ? 'Este campo es requerido'
              : undefined
          "
          name="productTitle"
          :label="'Nombre del Producto'"
        />
      </div>

      <div class="form-group">
        <label for="productDescription">Descripción del Producto</label>
        <textarea
          class="form-control"
          id="productDescription"
          v-model="store.currentProduct.description"
          rows="3"
        ></textarea>
        <p
          v-if="handlerValidate?.['description']?.$errors?.length > 0"
          v-text="'Este campo es requerido'"
        />
      </div>
      <div class="form-group">
        <TextField
          id="productPrice"
          v-model="store.currentProduct.price"
          :errorMessage="
            handlerValidate?.['price']?.$errors?.length > 0
              ? 'Este campo es requerido'
              : undefined
          "
          name="productPrice"
          :label="'Precio del Producto (USD)'"
        />
        <!-- <label for="productPrice">Product Price</label>
          <input
            type="number"
            class="form-control"
            id="productPrice"
            v-model="currentProduct.price"
            required
          /> -->
      </div>
      <div class="form-group">
        <label class="mb-2" for="productCategory">Categoría del Producto</label>
        <div class="row gap-3 check-form">
          <CheckBoxInput
            class="gap-1 col-2 edit-p"
            v-for="(category, index) in categories"
            :key="category.id"
            :value="category.id"
            :for="`${index}`"
            :text="category.name"
            :checked="isInCurrentProduct(category.id)"
            @change="checkOptionUpdate(category.id)"
          />
        </div>
      </div>

      <div class="form-group pt-4 pb-2">
        <label class="style-check font-medium text-tertiary">
          <input type="checkbox" v-model="susc" />
          <span class="checkmark checkmark__blue" />
          <span v-text="'Producto de  suscripción '" />
        </label>
      </div>

      <div class="form-group">
        <label for="url">Url del curso</label>
        <input
          type="url"
          name="url"
          class="form-control"
          v-model="store.currentProduct.external_url"
          id="url"
          placeholder="https://example.com"
          pattern="https://.*"
        />

        <div class="textField">
          <p
            v-if="showError"
            class="error-message"
            v-text="'Por favor, ingresa una URL válida.'"
          />
        </div>
      </div>

      <!-- <div class="form-group">
        <label for="productCategory">Product Category</label>
        <select
          class="form-control"
          id="productCategory"
          v-model="store.currentProduct.category"
          required
        >
          <option
            v-for="category in categories"
            :key="category.id"
            :value="category.id"
          >
            {{ category.name }}
          </option>
        </select>
      </div> -->
      <!-- Portrait Image Upload -->
      <div class="form-group">
        <label class="mb-2" for="productPortrait">Imagen del producto</label
        ><br />
        <img
          class="img-styles"
          v-if="store.currentProduct.image"
          :src="store.currentProduct.image"
        />

        <input
          type="file"
          class="form-control-file"
          id="productPortrait"
          @change="uploadPortraitImage($event)"
          accept="image/*"
        />
      </div>

      <div id="modulesContainer">
        <h4 class="mt-4">Módulos</h4>
        <div
          class="card"
          v-for="(module, moduleIndex) in store.currentProduct.modules"
          :key="moduleIndex"
          :id="'module' + (moduleIndex + 1)"
        >
          <div class="card-body">
            <h5 class="card-title">Módulo {{ moduleIndex + 1 }}</h5>
            <div class="form-group">
              <label :for="'moduleTitle' + (moduleIndex + 1)"
                >Título del módulo</label
              >
              <input
                type="text"
                class="form-control"
                :id="'moduleTitle' + (moduleIndex + 1)"
                v-model="module.title"
                required
              />
            </div>
            <div :id="'lessonsContainer' + (moduleIndex + 1)">
              <h6>Lecciones</h6>
              <div
                class="card mb-4"
                v-for="(lesson, lessonIndex) in module.lessons"
                :key="lessonIndex"
                :id="'lesson' + (moduleIndex + 1) + '_' + (lessonIndex + 1)"
              >
                <div class="card-body">
                  <h6 class="card-title">Lección {{ lessonIndex + 1 }}</h6>
                  <div class="form-group">
                    <label
                      :for="
                        'lessonTitle' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      >Título de la lección</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      :id="
                        'lessonTitle' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      v-model="lesson.title"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label
                      :for="
                        'lessonContent' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      >Contenido de la lección</label
                    >
                    <quill-editor
                      v-model:value="
                        store.currentProduct.modules[moduleIndex].lessons[
                          lessonIndex
                        ].content
                      "
                      :ref="quillEditorRef"
                      :options="state.editorOption"
                      class="quill-editor"
                      id="ql-editor"
                      @change="onEditorChange($event, moduleIndex, lessonIndex)"
                    />
                  </div>
                  <div class="form-group">
                    <label
                      class="mb-2"
                      :for="
                        'lessonVideo' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      >Cargar vídeo</label
                    ><br />
                    {{ lesson.video_name }}
                    <br />
                    <input
                      type="file"
                      class="form-control-file"
                      :id="
                        'lessonVideo' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      @change="uploadVideo($event, moduleIndex, lessonIndex)"
                    />
                  </div>
                  <button
                    type="button"
                    class="btn btn-danger"
                    @click="
                      removeLesson(
                        moduleIndex,
                        lesson.id || lesson._id,
                        lessonIndex
                      )
                    "
                  >
                    Eliminar lección
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-secondary mt-2 mx-2"
              @click="addLesson(moduleIndex)"
            >
              Añadir Lección
            </button>

            <button
              type="button"
              class="btn btn-danger mt-2"
              @click="removeModule(module?.id || module?._id, moduleIndex)"
            >
              Quitar módulo
            </button>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn btn-secondary mt-4 mx-2"
        @click="addModule"
      >
        Agregar módulo
      </button>
      <button type="submit" class="btn btn-primary mt-4">Subir</button>
    </form>
    <div v-if="showConfirmDialog" class="modal" style="display: block">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirmar Eliminación</h5>
            <button
              type="button"
              class="btn-close"
              @click="cancelDelete"
            ></button>
          </div>
          <div class="modal-body">
            <p>
              ¿Estás seguro de que deseas eliminar este elemento? Esta acción no
              se puede deshacer.
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="cancelDelete"
            >
              Cancelar
            </button>
            <button type="button" class="btn btn-danger" @click="confirmDelete">
              Eliminar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showConfirmDialog" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import "quill/dist/quill.snow.css";
import TextField from "@/components/common/TextField.vue";
import { useProductStore } from "@/stores/productStore";
import CheckBoxInput from "@/components/common/CheckInput.vue";
import { showNotification } from "@/composables/useNotification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { onMounted, reactive, ref } from "vue";
import router from "@/router";
import { quillEditor } from "vue3-quill";

export default {
  name: "ProductForm",
  components: {
    TextField,
    CheckBoxInput,
    quillEditor,
  },
  setup() {
    const store = useProductStore();
    // const { getAccessTokenSilently } = useAuth0();
    const currentProduct = ref(store.currentProduct);
    const categories = ref([]);
    const susc = ref(false);
    const showError = ref(false);
    const showConfirmDialog = ref(false);
    const pendingDeleteAction = ref(null);
    const state = reactive({
      content: "",
      _content: "",
      editorOption: {
        debug: "info",
        placeholder: "Escribe el contenido de la lección",
        readonly: false,
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"], // Text formatting
            [{ header: 1 }, { header: 2 }], // Header options
            [{ list: "ordered" }, { list: "bullet" }], // List options
            ["link"], // Link option
            [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
            ["clean"], // Clear formatting
            ['code-block']
          ],
        },
      },
      disabled: false,
    });
    const onEditorChange = (a, b, c) => {
      store.currentProduct.modules[b].lessons[c].content = a.html;
    };
    const handlerValidate = useVuelidate(
      {
        title: {
          required,
        },
        description: {
          required,
        },
        price: {
          required,
        },
      },
      store.currentProduct
    );
    const isInCurrentProduct = (id) => {
      const index = store.currentProduct.categories.findIndex(
        (item) => item === id
      );
      if (index > -1) {
        return true;
      }
      return false;
    };

    const fetchAndSetCategories = async () => {
      await store.fetchCategories();
      categories.value = store.categories;
      susc.value = store.currentProduct.is_sub;
    };

    const checkOptionUpdate = (event) => {
      const index = store.currentProduct.categories.indexOf(event);
      if (index !== -1) {
        store.currentProduct.categories.splice(index, 1);
      } else {
        store.currentProduct.categories.push(event);
      }
    };
    function findValue(obj, targetValue) {
      return Object.values(obj).some(
        (value) =>
          value === targetValue ||
          (typeof value === "object" &&
            value !== null &&
            findValue(value, targetValue))
      );
    }
    const addModule = () => store.addModule();
    const removeModule = async (moduleIndex) => {
      pendingDeleteAction.value = {
        type: "module",
        moduleIndex,
      };
      showConfirmDialog.value = true;
    };

    const addLesson = (moduleIndex) => store.addLesson(moduleIndex);
    const removeLesson = async (moduleIndex, lessonIndex, localIndex) => {
      pendingDeleteAction.value = {
        type: "lesson",
        moduleIndex,
        lessonIndex,
        localIndex,
      };
      showConfirmDialog.value = true;
    };

    const cancelDelete = () => {
      showConfirmDialog.value = false;
      pendingDeleteAction.value = null;
    };
    const confirmDelete = async () => {
      if (pendingDeleteAction.value.type === "lesson") {
        if (
          findValue(
            store.currentProduct.modules,
            pendingDeleteAction.value.lessonIndex
          )
        ) {
          await store.removeLesson(
            pendingDeleteAction.value.moduleIndex,
            pendingDeleteAction.value.lessonIndex
          );
        } else {
          await store.removeLesson(
            pendingDeleteAction.value.moduleIndex,
            pendingDeleteAction.value.lessonIndex,
            pendingDeleteAction.value.localIndex
          );
        }
        showConfirmDialog.value = false;
      } else if (pendingDeleteAction.value.type === "module") {
        if (
          findValue(
            store.currentProduct.modules,
            pendingDeleteAction.value.moduleIndex
          )
        ) {
          await store.removeModule(pendingDeleteAction.value.moduleIndex);
        }
        showConfirmDialog.value = false;
      }
    };
    const uploadPortraitImage = (event) => {
      const file = event.target.files[0];
      store.currentProduct.portraitImage = file;
    };

    const uploadVideo = async (event, moduleIndex, lessonIndex) => {
      const file = event.target.files[0];
      store.currentProduct.modules[moduleIndex].lessons[lessonIndex].videoFile =
        file;
    };

    // const validateInput = (event) => {
    //   let value = event.target.value;
    //   value = value.replace(/[^0-9,.]/g, "");

    //   if (value.includes(",")) {
    //     value = value.replace(",", ".");
    //   }
    //   const parts = value.split(".");
    //   if (parts.length > 2) {
    //     value =
    //       parts[0] +
    //       "." +
    //       parts
    //         .slice(1, 3)
    //         .join("")
    //         .replace(/[^0-9]/g, "");
    //   } else if (parts.length === 2) {
    //     //error máximo dos decimales
    //     value = parts[0] + "." + parts[1].slice(0, 2).replace(/[^0-9]/g, "");
    //   }

    //   currentProduct.value.price = value;
    // };
    const submitForm = async () => {
      const _validate = await handlerValidate.value.$validate();

      if (!_validate) return;

      const formData = new FormData();
      formData.append("title", store.currentProduct.title);
      formData.append("description", store.currentProduct.description);
      formData.append("price", store.currentProduct.price);
      formData.append(
        "categories",
        JSON.stringify(store.currentProduct.categories)
      );
      formData.append("is_sub", susc.value);
      formData.append(
        "external_url",
        store.currentProduct.external_url
          ? store.currentProduct.external_url
          : ""
      );

      if (store.currentProduct.portraitImage) {
        formData.append("portraitImage", store.currentProduct.portraitImage);
      }

      const modulesData = store.currentProduct.modules.map((module) => ({
        title: module.title,
        id: module.id,
        lessons: module.lessons.map((lesson) => ({
          title: lesson.title,
          content: lesson.content,
          id: lesson.id,
        })),
      }));

      formData.append("modules", JSON.stringify(modulesData));

      store.currentProduct.modules.forEach((module) => {
        module.lessons.forEach((lesson) => {
          if (lesson.videoFile) {
            formData.append(
              `file_${module.title}_${lesson.title}`,
              lesson.videoFile
            );
          }
        });
      });

      for (let pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }

      try {
        const response = await store.editProduct(
          store.currentProduct.id,
          formData
        );

        if (response.status === 200) {
          setTimeout(() => {
            router.push({ name: "products" });
          }, "100");
          setTimeout(() => {
            showNotification("Producto editado correctamente", "success");
          }, "1000");
        }
      } catch (error) {
        showNotification("Algo ha ido mal", "error");
        console.error("Updating error:", error);
      }
    };

    onMounted(() => {
      fetchAndSetCategories();
    });

    return {
      currentProduct,
      categories,
      addModule,
      removeModule,
      addLesson,
      removeLesson,
      uploadVideo,
      submitForm,
      uploadPortraitImage,
      handlerValidate,
      checkOptionUpdate,
      isInCurrentProduct,
      susc,
      showError,
      store,
      cancelDelete,
      confirmDelete,
      showConfirmDialog,
      onEditorChange,
      state,
      // validateInput,
    };
  },
};
</script>

<style scoped>
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  background-color: #2b2b2b;
  color: white;
}

.ql-container {
  color: white;
}

.ql-editor {
  color: white;
}

.ql-editor p {
  color: white !important;
}

.ql-editor span {
  color: white !important;
}

.quill-editor {
  height: 300px;
}
.content {
  margin-left: 240px;
  padding: 20px;
}
.form-group {
  margin-bottom: 20px;
}
label {
  font-weight: 500;
}
.img-styles {
  width: 150px;
  border-radius: 4px;
  margin-right: 20px;
}
.edit-p .style-check .checkmark {
  top: 25px !important;
}
</style>
