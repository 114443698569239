import axios from "axios"
import { defineStore } from "pinia"

export const useAffiliateStore = defineStore("Affiliate", {
  state: () => ({
    _status: null,
    _error: null,
    _affiliate: null,
    _affiliateProducts: [],
    _totalProducts: 0,
    _isAffiliate: false,
    _verifiedUser: false
  }),
  getters: {
    isLoading: (state) => state._status === "loading",
    isLoadingAll: (state) => state._status === "loadingAll",
    isReadyAll: (state) => state._status === "readyAll",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    affiliate: (state) => state._affiliate,
    affiliateProducts: (state) => state._affiliateProducts,
    totalProducts: (state) => state._totalProducts,
    isAffiliate: (state) => state._isAffiliate,
    verifiedUser: (state) => state._verifiedUser,
  },
  actions: {
    async getAffiliates(user) {
      this.changeStatus("loading");

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/affiliate-products/?affiliate__user__auth0_id=${user}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        this._affiliate = response.data.results;
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching list:", error);
      }
    },

    async getAffiliateProducts(userId, page = 1) {
      this.changeStatus("loading");
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/affiliate-products/`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
            params: {
              page,
              ...(userId ? { user_id: userId } : {})
            }
          }
        );
        
        this._affiliateProducts = response.data.results;
        this._totalProducts = response.data.count || 0;
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching affiliate products:", error);
      }
    },

    async verifyAffiliate() {
      this.changeStatus("loading");
      this._verifiedUser = 'loading'
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/affiliates/`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.status == 200 && response.data.length > 0){
          this._verifiedUser = 'verified'
        }else{
          this._verifiedUser = 'error'
        }
        this.changeStatus("ready");
      } catch (error) {
        console.log(error);
        this._verifiedUser = 'error'
        this.changeStatus("error");
      }
    },

    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
  },
});