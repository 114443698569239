import { defineStore } from "pinia";
import axios from 'axios';

export const useSubscriptionsStore = defineStore("subscriptions", {
  state: () => ({
    _status: null,
    _error: null,
    _subscriptions: [],
    _count: 0,
    _currentPage: 1,
    _pageSize: 10,
  }),

  getters: {
    isLoading: (state) => state._status === "loading",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    subscriptions: (state) => state._subscriptions.map(sub => ({
      id: sub.id,
      firstName: sub.user.first_name,
      lastName: sub.user.last_name,
      total: sub.total ? sub.total : "-",
      payment: {
        next: sub.next_payment_date,
        last: sub.last_payment_date
      },
      status: sub.state,
      cardNumber: sub?.card?.vivid_info?.number,
      email: sub.sale_data.user_email
    })),
    count: (state) => state._count,
    currentPage: (state) => state._currentPage,
    pageCount: (state) => Math.ceil(state._count / state._pageSize),
  },

  actions: {
    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },

    async getSubscriptions({ productId = null, name = '', lastName = '', email = "", status = '', page = 1,sortObject }) {
      if (!productId) {
        this._subscriptions = [];
        this._count = 0;
        this._currentPage = 1;
        this.changeStatus("ready");
        return;
      }

      this.changeStatus("loading");
      
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/subscriptions/`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
            params: {
              page,
              product: productId,
              ...(name ? { first_name: name.trim() } : {}),
              ...(lastName ? { last_name: lastName.trim() } : {}),
              ...(status ? { status: status.trim() } : {}),
              ...(email ? { email: email.trim() } : {}),
              ...(sortObject ? { order: `${[sortObject.value]}:${sortObject.order}` } : {}),
            },
          }
        );
        
        if (response.status === 200) {
          this._subscriptions = response.data.results;
          this._count = response.data.count;
          this._currentPage = page;
        }
    
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error", error.response?.data || error.message);
        console.error("Error fetching subscriptions:", error.response?.data || error.message);
      }
    },

    async cancelSubscription(subId) {
      this.changeStatus("loading");
      
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/cancel-subscription/`,
          { sub_id: subId },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
    
        if (response.status === 200) {
          await this.getSubscriptions({ 
            productId: this._subscriptions[0]?.product?.id,
            page: this._currentPage 
          });
        }
    
        this.changeStatus("ready");
        return response.data;
      } catch (error) {
        this.changeStatus(
          "error", 
          error.response?.data || error.message
        );
        console.error(
          "Error canceling subscription:", 
          error.response?.data || error.message
        );
        throw error;
      }
    },

    setPage(page) {
      this._currentPage = page;
    },
  },
});