import axios from "axios"
import { defineStore } from "pinia"

export const useOffersStore = defineStore("offers", {
  state: () => ({
    _status: null,
    _error: null,
    _data: null,
  }),
  getters: {
    isLoading: (state) => state._status === "loading",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    data: (state) => state._data,
  },
  actions: {
    async getOffers() {
      this.changeStatus("loading");

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/offers/`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200)
          this._data = response.data;
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching list:", error);
      }
    },

    async createOffer(offerData) {
      this.changeStatus("loading");

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/offers/`,
          offerData,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 201) {
          // Assuming the API returns the created offer
          this._data = [...this._data, response.data];
          this.changeStatus("ready");
        }
      } catch (error) {
        this.changeStatus("error");
        console.error("Error creating offer:", error);
        throw error;
      }
    },

    async deleteOffer(uid) {
      this.changeStatus("loading");

      try {
        const response = await axios.delete(
          `${process.env.VUE_APP_API_URL}/api/offers/${uid}/`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200) {
          // Remove the deleted offer from the local state
          this._data = this._data.filter(offer => offer.uid !== uid);
          this.changeStatus("ready");
        }
      } catch (error) {
        this.changeStatus("error");
        console.error("Error deleting offer:", error);
        throw error;
      }
    },

    async getAffiliateOffers() {
      this.changeStatus("loading");

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/offers/?affiliate=yes`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200)
          this._data = response.data;
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching list:", error);
      }
    },

    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
  },
});