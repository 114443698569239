<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light w-100 bg-white">
    <a class="navbar-brand" href="#">
      <img :src="userInfo?.picture || defaultAvatar" alt="Profile Picture" class="profile-picture">
    </a>
    <button 
      class="navbar-toggler" 
      type="button" 
      data-bs-toggle="collapse" 
      data-bs-target="#navbarNav" 
      aria-controls="navbarNav" 
      aria-expanded="false" 
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item dropdown" v-if="isAuthenticated">
          <a 
            class="nav-link dropdown-toggle" 
            href="#" 
            id="navbarDropdown" 
            role="button" 
            data-bs-toggle="dropdown" 
            aria-haspopup="true" 
            aria-expanded="false"
          >
            <img 
              :src="userInfo?.picture || defaultAvatar" 
              alt="Profile Picture" 
              class="profile-picture-sm"
            > 
            {{ userInfo?.name || userInfo?.email || 'Usuario' }}
          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li>
              <router-link to="/profile" class="dropdown-item">
                {{ $t('PROFILE.PROFILE') }}
              </router-link>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li>
              <a class="dropdown-item" href="#" @click.prevent="handleLogout">
                Cerrar sesión
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item" v-else>
          <router-link class="nav-link" to="/login">
            Iniciar sesión
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useAuth } from '@/composables/useAuth';

const { isAuthenticated, getToken, handleLogout } = useAuth();
const userInfo = ref(null);
const defaultAvatar = 'https://cdn.auth0.com/avatars/default.png';

const fetchUserInfo = async () => {
  try {
    const token = await getToken();
    if (!token) return;

    const response = await fetch(`https://${process.env.VUE_APP_AUTH0_DOMAIN}/userinfo`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user info');
    }

    userInfo.value = await response.json();
  } catch (error) {
    console.error('Error fetching user info:', error);
  }
};

onMounted(() => {
  if (isAuthenticated.value) {
    fetchUserInfo();
  }
});
</script>

<style scoped>
.navbar {
  padding: 10px 20px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
}

.nav-link {
  color: #343a40 !important;
}

.nav-link:hover {
  color: #007bff !important;
}

.profile-picture {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.profile-picture-sm {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 8px;
}
</style>