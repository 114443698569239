import axios from "axios"
import { defineStore } from "pinia"
import { useCoreStore } from './core'

export const useReportsStore = defineStore("reports", {
  state: () => ({
    _status: null,
    _error: null,
    _reports: null,
    _pageCount: 0,
    _page: 1,
    _pageSize: 10
  }),
  
  getters: {
    isLoading: (state) => state._status === "loading",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    reports: (state) => state._reports,
    pageCount: (state) => state._pageCount,
    page: (state) => state._page,
  },
  
  actions: {
    async getReports(pageNumber = 1) {
      const coreStore = useCoreStore();
      const subdomain = coreStore.subdomain;
      let url = `${process.env.VUE_APP_API_URL}/api/sales-reports/?type=${subdomain === 'skillper' ? 'productor' : 'afiliado'}&page=${pageNumber}`;

      this.changeStatus("loading");

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        });
        
        this._pageCount = Math.ceil(response.data.count / this._pageSize);
        this._page = pageNumber;
        this._reports = response.data.results;
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching reports:", error);
      }
    },

    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
  },
});