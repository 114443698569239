<script setup>
import { defineProps, ref } from "vue"
import SwitchInput from "../common/SwitchInput.vue"

const API_URL = process.env.VUE_APP_API_URL

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});

const errorImage = ref(false);
const isVisible = ref(props.product.visible);

const toggleVisibility = async (event) => {
  try {
    const formData = new FormData()
    formData.append('visible', event.target.checked)

    const response = await fetch(`${API_URL}/api/products/${props.product.id}/`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: formData
    })

    if (!response.ok) {
      isVisible.value = !event.target.checked
      throw new Error('Failed to update visibility')
    }

    isVisible.value = event.target.checked

  } catch (error) {
    console.error('Error updating product visibility:', error)
    isVisible.value = !event.target.checked
  }
}
</script>

<template>
  <div class="card" style="">
    <img
        v-if="errorImage || !product.image"
        src="@/assets/img/emptyimg.svg" class="card-img-top" alt="...">
      <img
        v-else
        :src="props.product.image" class="card-img-top" alt="...">
    <div class="card-body">
      <div class="card-title fw-bold text-uppercase">{{ props.product.title }}</div>
      <p class="card-text content-description truncate">
        {{ product.description }}
      </p>

      <p v-if="props.product.status != 'aceptado'"  class="aprobation-status" v-text="'Pendiente de aprobación'"/>

      <div class="d-flex justify-content-between align-items-center mb-2">
        <SwitchInput :isChecked="props.product.active" :productId="props.product.id" />
        <div class="d-flex align-items-center gap-2">
          <label class="form-check-label small mb-0" for="visibilityCheck">Visible</label>
          <input 
            type="checkbox" 
            class="form-check-input mt-0" 
            id="visibilityCheck"
            :checked="isVisible"
            @change="toggleVisibility">
        </div>
      </div>

      <div class="d-inline-flex gap-2 pt-4 w-full justify-content-end ">
        <button 
          @click="$emit('delete', props.product.id)" 
          type="button" class="btn btn-outline-danger btn-sm"
        >
          <i class="fas fa-trash"></i> Borrar
        </button>
        <button 
          @click="$emit('edit', props.product.id)"
          type="button"
          class="btn btn-outline-dark btn-sm"
        >
          <i class="fas fa-edit"></i> Editar
        </button>
        <button 
          @click="$emit('affiliates', props.product.id)"
          type="button"
          class="btn btn-dark btn-sm"
        >
          Afiliados
        </button>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <a 
          :href="`https://course.skillpering.com/lesson/${props.product.id}`"
          target="_blank"
          class="btn btn-dark btn-sm w-100"
        >
          Ir al curso
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #6c757d;
}
.content-description {
  min-height: 65px !important;
}

.card-title {
  font-weight: 900;
  font-family: 'Poppins';
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #464545;
}

.aprobation-status {
  background: #FF5722;
  max-width: 56%;
  font-size: 14px;
  border-radius: 5px;
  color: white;
  padding: 2px 9px;
}
</style>