import axios from "axios";
import { defineStore } from "pinia";
function* idGenerator() {
  let id = 1;
  while (true) {
    yield `id-${id++}`;
  }
}
const API_URL = process.env.VUE_APP_API_URL;

const generateId = idGenerator();
export const useProductStore = defineStore("productStore", {
  state: () => ({
    products: [],
    categories: [],
    currentProduct: {
      title: "",
      description: "",
      price: "",
      portraitImage: "",
      categories: [],
      is_sub: false,
      external_url: "",
      modules: [
        {
          _id: generateId.next().value,
          title: "",
          lessons: [
            {
              _id: generateId.next().value,
              title: "",
              content: "",
              videoUrl: "",
            },
          ],
        },
      ],
    },
    _listProducts: [],
    _status: null,
  }),
  getters: {
    isLoading: (state) => state._status === "loading",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    listProducts: (state) => state._listProducts,
  },
  actions: {
    async fetchCategories() {
      try {
        const response = await axios.get(`${API_URL}/api/categories/`);
        this.categories = response.data;
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async createProduct() {
      try {
        const productData = this.currentProduct;
        const response = await axios.post(
          `${API_URL}/api/products/`,
          productData
        );
        this.products.push(response.data);
        this.resetCurrentProduct();
      } catch (error) {
        console.error("Error creating product:", error);
      }
    },
    async getAllProducts(user) {
      try {
        const response = await axios.get(`${API_URL}/api/producers/${user}/`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        });
        this._listProducts = response.data;
      } catch (error) {
        console.error("Error fetching list:", error);
      }
    },
    async deleteProduct(_id) {
      this.changeStatus("loading");

      try {
        const response = await axios.delete(
          `${API_URL}/api/producer-products/${_id}/`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 204) {
          this.changeStatus("ready");
        }
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching list:", error);
      }
    },
    async getProduct(_id) {
      this.changeStatus("loading");

      try {
        const response = await axios.get(
          `${API_URL}/api/products/${_id}/simple-product/`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.currentProduct = response.data;
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching list:", error);
      }
    },
    async editProduct(_id, _formData) {
      this.changeStatus("loading");

      try {
        const response = await axios.put(
          `${API_URL}/api/products/${_id}/`,
          _formData,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 204) {
          this.changeStatus("ready");
        }
        return response;
      } catch (error) {
        this.changeStatus("error");
        console.error("Error fetching list:", error);
      }
    },
    async activeInactiveProduct(id, val) {
      const product = this.listProducts.products.find((item) => item.id === id);
      product.active = val;

      const formData = new FormData();
      formData.append("active", val);
      await this.editProduct(id, formData);
    },

    resetCurrentProduct() {
      this.currentProduct = {
        title: "",
        description: "",
        price: "",
        portraitImage: "",
        categories: [],
        is_sub: false,
        external_url: "",
        modules: [
          {
            _id: generateId.next().value,
            title: "",
            lessons: [
              {
                _id: generateId.next().value,
                title: "",
                content: "",
                videoUrl: "",
              },
            ],
          },
        ],
      }
    },
    addModule() {
      this.currentProduct.modules.push({
        title: "",
        _id: generateId.next().value,
        lessons: [
          {
            title: "",
            content: "",
            videoUrl: "",
          },
        ],
      });
    },
    async removeModule(moduleIndex) {
      this.changeStatus("loading");
      try {
        if (typeof moduleIndex == "number") {
          const response = await axios.delete(
            `${API_URL}/api/modules/${moduleIndex}/`,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response.status < 200 || response.status > 210) {
            console.log(`Unexpected response status: ${response.status}`);
          }
        }
        this.currentProduct.modules = this.currentProduct.modules.filter(
          (module) =>
            typeof moduleIndex === "number"
              ? module.id !== moduleIndex
              : module._id !== moduleIndex
        );

        this.changeStatus("ready");
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    addLesson(moduleIndex) {
      this.currentProduct.modules[moduleIndex].lessons.push({
        title: "",
        content: "",
        videoUrl: "",
        _id: generateId.next().value,
      });
      console.log(this.currentProduct);
    },
    async removeLesson(moduleIndex, lessonIndex) {
      this.changeStatus("loading");
      try {
        if (typeof lessonIndex == "number") {
          const response = await axios.delete(
            `${API_URL}/api/lessons/${lessonIndex}/`,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          );
          if (response.status < 200 || response.status > 210) {
            console.log(`Unexpected response status: ${response.status}`);
          }
        }
        const module = this.currentProduct.modules[moduleIndex];
        module.lessons = module.lessons.filter((lesson) =>
          typeof lessonIndex === "number"
            ? lesson.id !== lessonIndex
            : lesson._id !== lessonIndex
        );
      } catch (error) {
        console.log("Error removing lesson:", error);
      }
    },
    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
  },
});
