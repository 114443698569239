<template>
  <td class="">
    <div v-if="isEditing">
      <input 
        type="number" 
        class="form-control"
        v-model="updatedPercentage" 
        @blur="emitUpdate"
        @keyup.enter="emitUpdate"
        ref="inputRef"
      />
    </div>
    <div v-else @click="enableEditing" class="editable-text">
      % {{ item.percentage || '-' }}
    </div>
  </td>
</template>

<script setup>
import { defineEmits, defineProps, ref, nextTick, watch } from "vue"

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})
const inputRef = ref(null);
const isEditing = ref(false)
const updatedPercentage = ref(props.item.percentage)
const originalPercentage = ref(props.item.percentage)
const emit = defineEmits(['update-item']);

const enableEditing = async () => {
  isEditing.value = true;
  originalPercentage.value = props.item.percentage;
  updatedPercentage.value = props.item.percentage;
  await nextTick();
  inputRef.value.focus();
};

const emitUpdate = async () => {
  if (updatedPercentage.value !== props.item.percentage) {
    const updatedItem = {
      ...props.item,
      percentage: updatedPercentage.value
    };
    const success = await emit('update-item', updatedItem, originalPercentage.value);
    if (!success) {
      updatedPercentage.value = originalPercentage.value;
    }
  }
  isEditing.value = false;
};

watch(() => props.item.percentage, (newValue) => {
  if (!isEditing.value) {
    updatedPercentage.value = newValue;
    originalPercentage.value = newValue;
  }
});
</script>