<script setup>
import { ref, onMounted, computed } from "vue";
import { useSubscriptionsStore } from "@/stores/SubscriptionsStore";
import { useProductStore } from "@/stores/productStore";
import DataTable from "@/components/common/DataTable.vue";
import PaginationControl from "@/components/common/PaginationControl.vue";
import { Modal } from "bootstrap";
import { useRouter } from 'vue-router';

const router = useRouter();
const subscriptionsStore = useSubscriptionsStore();
const productStore = useProductStore();

const selectedProductId = ref("");
const isLoading = ref(true);
const nameFilter = ref("");
const lastNameFilter = ref("");
const emailFilter = ref("");
const statusFilter = ref("");
const subscriptionToCancel = ref(null);
const currentPage = ref(1);
let cancelModal = null;

const getUserIdFromToken = () => {
  try {
    const token = sessionStorage.getItem('token');
    if (token) {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.sub;
    }
    return null;
  } catch (error) {
    console.error('Error getting user ID:', error);
    return null;
  }
};

onMounted(async () => {
  isLoading.value = true;
  const token = sessionStorage.getItem('token');
  if (!token) {
    router.push('/login');
    return;
  }

  const userId = getUserIdFromToken();
  if (!userId) {
    router.push('/login');
    return;
  }

  await productStore.getAllProducts(userId);
  isLoading.value = false;
  cancelModal = new Modal(document.getElementById("cancelSubscriptionModal"));
});
const sortFuncion = async (sortObject)=>{
  if (selectedProductId.value) {
    await subscriptionsStore.getSubscriptions({
      productId: selectedProductId.value,
      name: nameFilter.value,
      lastName: lastNameFilter.value,
      email: emailFilter.value,
      status: statusFilter.value,
      page: 1,
      sortObject
    });
  }
}
const handlePageChange = async (page) => {
  currentPage.value = page;
  if (selectedProductId.value) {
    await subscriptionsStore.getSubscriptions({
      productId: selectedProductId.value,
      name: nameFilter.value,
      lastName: lastNameFilter.value,
      email: emailFilter.value,
      status: statusFilter.value,
      page: page,
    });
  }
};

const loadSubscriptions = async () => {
  currentPage.value = 1;
  if (selectedProductId.value) {
    await subscriptionsStore.getSubscriptions({
      productId: selectedProductId.value,
      name: nameFilter.value,
      lastName: lastNameFilter.value,
      email: emailFilter.value,
      status: statusFilter.value,
      page: 1,
    });
  }
};

const subscriptionProducts = computed(() =>
  (productStore.listProducts.products || []).filter((product) => product.is_sub)
);

const hasSubscriptionProducts = computed(
  () => subscriptionProducts.value.length > 0
);

const titlesTable = computed(() => [
  { width: "15%", title: "Nombre",/* dataName:"first_name" */ },
  { width: "15%", title: "Apellido",/* dataName:"last_name" */ },
  { width: "15%", title: "Email",/* dataName:"user_email" */ },
  { width: "15%", title: "Total",dataName:"total" },
  { width: "15%", title: "Siguiente Pago",dataName:"next_payment_date" },
  { width: "15%", title: "Último Pago",dataName:"last_payment_date" },
  { width: "15%", title: "Estado" },
  { width: "15%", title: "Tarjeta" },
  { width: "15%", title: "Cancelar" },
]);

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return dateString;
};

const formatTotal = (total) => {
  if (total === "-") return total;
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(total);
};

const getStatusClass = (status) => {
  switch (status.toLowerCase()) {
    case "active":
      return "badge bg-success";
    case "completed":
      return "badge bg-primary";
    case "issue":
      return "badge bg-warning";
    case "archived":
      return "badge bg-secondary";
    default:
      return "badge bg-secondary";
  }
};

const handleCancelSubscription = (subscriptionId) => {
  subscriptionToCancel.value = subscriptionId;
  cancelModal.show();
};

const confirmCancelSubscription = async () => {
  try {
    await subscriptionsStore.cancelSubscription(subscriptionToCancel.value);
    cancelModal.hide();
    await loadSubscriptions();
  } catch (error) {
    console.error("Error al cancelar la suscripción:", error);
  }
};
</script>

<template>
  <section>
    <h2 class="h2 mb-4">Suscripciones</h2>

    <div v-if="isLoading" class="text-center py-4">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Cargando...</span>
      </div>
    </div>

    <template v-else>
      <div class="mb-4">
        <div class="row g-3">
          <div class="col-md-3">
            <select
              id="product-select"
              v-model="selectedProductId"
              class="form-select"
              @change="loadSubscriptions"
            >
              <option value="">Selecciona un Producto</option>
              <option
                v-for="product in subscriptionProducts"
                :key="product.id"
                :value="product.id"
              >
                {{ product.title }}
              </option>
            </select>
          </div>
          <div class="col-md-2">
            <input
              type="text"
              v-model="nameFilter"
              placeholder="Filtrar por nombre"
              class="form-control"
            />
          </div>
          <div class="col-md-2">
            <input
              type="text"
              v-model="lastNameFilter"
              placeholder="Filtrar por apellido"
              class="form-control"
            />
          </div>
          <div class="col-md-3">
            <input
              type="text"
              v-model="emailFilter"
              placeholder="Filtrar por email"
              class="form-control"
            />
          </div>
          <div class="col-md-2">
            <select v-model="statusFilter" class="form-select">
              <option value="">Estado</option>
              <option value="active">Active</option>
              <option value="completed">Completed</option>
              <option value="issue">Issue</option>
              <option value="archived">Archived</option>
            </select>
          </div>
          <div class="col-md-1">
            <button
              @click="loadSubscriptions"
              class="search-button"
              :disabled="!selectedProductId"
            >
              Buscar
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="!hasSubscriptionProducts"
        class="alert alert-info mb-4 text-center py-4"
      >
        No tienes productos de suscripción
      </div>

      <div v-else-if="!selectedProductId" class="mb-4 text-center py-4">
        Por favor, selecciona un producto para ver las suscripciones
      </div>

      <div v-else-if="subscriptionsStore.isLoading" class="text-center py-4">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Cargando suscripciones...</span>
        </div>
      </div>

      <div
        v-else-if="subscriptionsStore.isError"
        class="alert alert-danger text-center py-4"
      >
        Error al cargar suscripciones: {{ subscriptionsStore.error }}
      </div>

      <template v-else>
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div>
            <strong>Total de suscripciones:</strong>
            {{ subscriptionsStore.count }}
          </div>
        </div>

        <div class="table-responsive">
          <DataTable
            :noHaveData="!subscriptionsStore.subscriptions.length"
            :headers="titlesTable"
            :sortFuncion="sortFuncion"
          >
            <template #body>
              <tr
                v-for="subscription in subscriptionsStore.subscriptions"
                :key="subscription.id"
              >
                <td class="sticky-column">{{ subscription.firstName }}</td>
                <td>{{ subscription.lastName }}</td>
                <td>{{ subscription.email }}</td>
                <td>{{ formatTotal(subscription.total) }}</td>
                <td>{{ formatDate(subscription.payment.next) }}</td>
                <td>{{ formatDate(subscription.payment.last) }}</td>
                <td>
                  <span :class="getStatusClass(subscription.status)">
                    {{ subscription.status }}
                  </span>
                </td>
                <td>**** **** **** {{ subscription.cardNumber }}</td>
                <td>
                  <button
                    @click="handleCancelSubscription(subscription.id)"
                    class="btn btn-danger btn-sm"
                    :disabled="
                      subscription.status.toLowerCase() !== 'active' ||
                      subscriptionsStore.isLoading
                    "
                  >
                    <span
                      v-if="subscriptionsStore.isLoading"
                      class="spinner-border spinner-border-sm me-1"
                      role="status"
                    >
                      <span class="visually-hidden">Cargando...</span>
                    </span>
                    Cancelar<br />suscripción
                  </button>
                </td>
              </tr>
            </template>
          </DataTable>
        </div>

        <PaginationControl
          v-if="subscriptionsStore.subscriptions.length && subscriptionsStore.pageCount > 1"
          :current-page="currentPage"
          :total-pages="subscriptionsStore.pageCount"
          @page-change="handlePageChange"
        />
      </template>
    </template>

    <div
      class="modal fade"
      id="cancelSubscriptionModal"
      tabindex="5"
      aria-labelledby="cancelSubscriptionModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="cancelSubscriptionModalLabel">
              ¿Cancelar suscripción?
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            ¿Estás seguro de que deseas cancelar esta suscripción?
          </div>
          <div class="modal-footer">
            <button type="button" class="cerrar-btn btn" data-bs-dismiss="modal">
              Cerrar
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="confirmCancelSubscription"
              :disabled="subscriptionsStore.isLoading"
            >
              <span
                v-if="subscriptionsStore.isLoading"
                class="spinner-border spinner-border-sm me-1"
                role="status"
              >
                <span class="visually-hidden">Cargando...</span>
              </span>
              Cancelar suscripción
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.table-responsive {
  overflow-x: auto;
}

.sticky-column {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 1;
}

.modal-footer {
  border: none;
}

.cerrar-btn {
  background-color: white;
  color: black;
}

.cerrar-btn:hover {
  background-color: rgb(212, 212, 212);
  color: black;
}

.search-button {
  background-color: #212429;
  color: #fff;
  border: none;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 6px;
  padding-top: 6px;
  border-radius: 6px;
}
</style>