/* eslint-disable */
<template>
  <div class="">
    <div class="main-title">
      <h2 class="section-title">{{ $t("PRODUCT.TITLE") }}</h2>
      <router-link to="/addProduct" class="btn btn-dark">
        <i class="fas fa-plus"></i>
        {{ $t("PRODUCT.ADD_PRODUCT") }}
      </router-link>
    </div>

    <div class="row">
      <template v-if="isLoading">
        <Skeleton v-for="index in 4" :key="index" class="col-md-6" />
      </template>
      <p class="" v-else-if="productStore?.listProducts?.products?.length === 0">
        No hay productos Agregados...
      </p>
      <template v-else>
        <div
          class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4 card-product"
          v-for="(item, index) in productStore.listProducts.products"
          :key="index"
        >
          <CardProduct
            @edit="editProduct"
            @delete="deleteProduct"
            @affiliates="getAffiliates"
            :product="item"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import CardProduct from "@/components/products/CardProduct.vue"
import Skeleton from "@/components/skeleton/CardSkeleton.vue"
import router from "@/router"
import { useProductStore } from "@/stores/productStore"
import { onMounted, ref } from "vue"

const productStore = useProductStore();
const isLoading = ref(true);

const getUserIdFromToken = () => {
  try {
    const token = sessionStorage.getItem('token');
    if (token) {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.sub;
    }
    return null;
  } catch (error) {
    console.error('Error getting user ID from token:', error);
    return null;
  }
};

const deleteProduct = async (_id) => {
  await productStore.deleteProduct(_id);
  await getProducts();
};

const editProduct = async (_id) => {
  await productStore.getProduct(_id);
  router.push({ name: "editProduct" });
};

const getAffiliates = async (_id) => {
  router.push({ name: "affiliates", params: { id: _id} });
};

const getProducts = async () => {
  try {
    const token = sessionStorage.getItem('token');
    if (!token) {
      router.push('/login');
      return;
    }

    const userId = getUserIdFromToken();
    if (!userId) {
      router.push('/login');
      return;
    }

    await productStore.getAllProducts(userId);
    isLoading.value = false;
  } catch (error) {
    console.error('Error fetching products:', error);
    if (error.response?.status === 401) {
      router.push('/login');
    }
    isLoading.value = false;
  }
};

onMounted(async () => {
  await getProducts();
  productStore.resetCurrentProduct();
});
</script>

<style scoped>
.main-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.section-title {
  margin: 0;
}
</style>